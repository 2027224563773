const API_BASE_URL = 'https://typeform.zendesk.com/api/v2'

// Important:
// The English locale in Zendesk is "en-us" (lowercase) but the Spanish on is just "es"!
const ZENDESK_EN_LOCALE = 'en-us'

const GROUP_IDS = {
  ARTICLES: 1,
}
export { API_BASE_URL, ZENDESK_EN_LOCALE, GROUP_IDS }
